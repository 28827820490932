import '@airgrid/components/style.css';
import './styles/global.scss';
import './styles/font-face.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Highcharts from 'highcharts';
import MapModule from 'highcharts/modules/map';
import NetworkGraphModule from 'highcharts/modules/networkgraph';
import OktaVue from '@okta/okta-vue';
import { logger } from '@/utils';
import App from './App.vue';
import { router } from './router/router';
import { initSentry } from '@/utils/sentry';
import { verticalScrollShadow, clickOutside } from '@/directives';
import { auth } from './auth';
import { oktaAuthClient } from '@/utils/okta';

MapModule(Highcharts);
NetworkGraphModule(Highcharts);

const pinia = createPinia();
const app = createApp(App);

initSentry(app);

void auth.initializeAuthProvider(() => {
  app.use(router);
  app.use(pinia);

  app.use(OktaVue, {
    oktaAuth: oktaAuthClient,
  });

  app.directive('click-outside', clickOutside);
  app.directive('vertical-scroll-shadow', verticalScrollShadow);

  app.config.errorHandler = (err, vm, info) => {
    // NOTE: `info` is Vue specific error info,
    // e.g. which lifecycle hook the error was seen in.
    logger.error(err, info);
  };

  app.mount('#app');
});
