<template>
  <div
    class="chip"
    data-testid="chip"
  >
    <div class="chip__body">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Chip',
});
</script>

<style lang="scss" scoped>
// @define chip

.chip {
  cursor: default;
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-popover);
}
.chip__body {
  background: rgba(0, 0, 0, 0.8);
  color: var(--color-blacks-white);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
  border-radius: 35px;
  padding: 16px 20px;
}
</style>
