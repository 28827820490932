import { computed, ref } from 'vue';

import { defineStore } from 'pinia';
import * as api from '@/store/api';
import {
  AccountInfo,
  Audience,
  AudienceStatus,
  AudienceType,
  UserInfo,
} from 'shared-types';
import { useAccountStore } from '@/store/modules/account/account-store';
import { useUserInterfaceStore } from '@/store/modules/user-interface';
import { useAudienceStore } from '@/store/modules/audiences/audience-store';
import { logger } from '@/utils';

export const useAdminStore = defineStore('admin-store', () => {
  const adminAccounts = ref<AccountInfo[] | undefined>();
  const isAdminAccountsLoaded = ref<boolean>(false);
  const adminAccountUsers = ref<UserInfo[] | undefined>();
  const isAdminAccountUsersLoaded = ref<boolean>(false);
  const accountStore = useAccountStore();
  const audienceStore = useAudienceStore();
  const userInterfaceStore = useUserInterfaceStore();

  const setAdminAccounts = async () => {
    isAdminAccountsLoaded.value = false;

    const loadingId = userInterfaceStore.setInitialLoading();
    const accounts = await api.getAccounts();

    adminAccounts.value = accounts;

    isAdminAccountsLoaded.value = true;
    userInterfaceStore.unsetInitialLoading(loadingId);
  };

  const unsetAdminAccounts = () => {
    adminAccounts.value = undefined;
    isAdminAccountsLoaded.value = false;
  };

  const deleteAccount = async (deletedAccountId: string) => {
    if (!adminAccounts.value) {
      throw new Error('Admin accounts are not loaded');
    }

    const accountId = accountStore.getSelectedAccountId();

    await api.removeAccount({ accountId, deletedAccountId });

    adminAccounts.value = adminAccounts.value.filter(
      (account) => account.id !== deletedAccountId
    );
  };

  const setAccountUsers = async () => {
    isAdminAccountUsersLoaded.value = false;
    const accountId = useAccountStore().selectedAccountId;

    if (accountId) {
      const users = await api.getUsersForAccount(accountId);
      adminAccountUsers.value = users;
      isAdminAccountUsersLoaded.value = true;
    }
  };

  const unsetAccountUsers = () => {
    adminAccountUsers.value = undefined;
    isAdminAccountUsersLoaded.value = false;
  };

  const getAdminAccounts = computed(() => {
    if (!adminAccounts.value) {
      throw new Error('Admin accounts are not loaded');
    }
    return adminAccounts.value;
  });

  const getAdminAccountUsers = computed(() => {
    if (!adminAccountUsers.value) {
      throw new Error('Admin account users are not loaded');
    }
    return adminAccountUsers.value;
  });

  const addAdminAccount = (account: AccountInfo) => {
    if (!adminAccounts.value) {
      throw new Error('Admin accounts are not loaded');
    }

    adminAccounts.value.push(account);
  };

  const updateAccountMargin = async ({
    accountId,
    minMarginPct,
  }: {
    accountId: string;
    minMarginPct: number;
  }) => {
    if (!adminAccounts.value) {
      throw new Error('Admin accounts are not loaded');
    }
    await api.updateAccountMargin({ accountId, minMarginPct });

    const accountIndex = adminAccounts.value.findIndex(
      (account) => account.id === accountId
    );
    if (accountIndex !== -1) {
      if (minMarginPct) {
        adminAccounts.value[accountIndex].minMarginPct = minMarginPct;
      }
    }
  };

  const feedAudiences = ref<Audience[]>();
  const isFeedAudiencesLoading = ref(false);
  const loadFeedAudiences = async () => {
    isFeedAudiencesLoading.value = true;
    const accountId = accountStore.getSelectedAccountId();
    try {
      feedAudiences.value = await api.getAudiences({
        accountId,
        type: AudienceType.FEED,
      });
    } finally {
      isFeedAudiencesLoading.value = false;
    }
  };

  const loadCrossAccountAudience = async (
    audienceId: string
  ): Promise<Audience | undefined> => {
    const accountId = accountStore.getSelectedAccountId();
    try {
      const audiences = await api.getAudiences({
        accountId,
        audienceId,
      });
      if (audiences.length > 0) {
        return audiences[0];
      }
    } catch (err) {
      logger.warn(`Cannot load a cross account audience ${audienceId}`);
    }
    return undefined;
  };

  const updateFeedAudienceStatus = async (
    audienceId: string,
    status: AudienceStatus
  ) => {
    if (!feedAudiences.value) {
      throw new Error('Feed audiences are not loaded');
    }

    await audienceStore.setAudienceStatus(audienceId, status);
    const index = feedAudiences.value.findIndex((a) => a.id === audienceId);
    if (index !== -1) {
      feedAudiences.value[index].status = status;
    }
  };

  return {
    setAdminAccounts,
    unsetAdminAccounts,
    deleteAccount,
    setAccountUsers,
    unsetAccountUsers,
    addAdminAccount,
    getAdminAccounts,
    getAdminAccountUsers,
    adminAccounts,
    isAdminAccountsLoaded,
    adminAccountUsers,
    isAdminAccountUsersLoaded,
    updateAccountMargin,
    loadFeedAudiences,
    feedAudiences,
    isFeedAudiencesLoading,
    updateFeedAudienceStatus,
    loadCrossAccountAudience,
  };
});
