import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { OktaAuth } from '@okta/okta-auth-js';

interface ErrorResponseRetry {
  _retry?: boolean;
}

interface CallOptions
  extends Omit<AxiosRequestConfig, 'url' | 'data' | 'method'> {
  allowUnauthenticated?: boolean;
}

interface ResponseError extends Error {
  config: AxiosRequestConfig & CallOptions & ErrorResponseRetry;
  response: AxiosResponse;
}

export class OktaAxiosInterceptor {
  constructor(private oktaAuthClient: OktaAuth) {}

  /**
   * Updates provided Axios with auth interceptor.
   *
   * The main goal of the interceptor is to process 401 error responses.
   * For intercepted response it refreshes access tokens and replays original request with
   * the new access token.
   * If token refresh was not successful then it fails with original error.
   *
   * @param {AxiosInstance} axiosInstance The Axios instance to update.
   */
  init(axiosInstance: AxiosInstance): void {
    const createAxiosResponseInterceptor = () => {
      const interceptor = axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => response,
        async (error: ResponseError) => {
          const originalRequest = error.config;
          if (
            error.response !== undefined &&
            error.response.status === 401 &&
            originalRequest?.allowUnauthenticated !== true
          ) {
            // NOTE: Sign out a user if retried request resulted in the same 401 error
            if (originalRequest._retry === true) {
              await this.oktaAuthClient.signOut();
              return Promise.reject(error);
            }

            // NOTE: Eject the interceptor so it doesn't loop in case
            // token refresh causes the 401 response.
            // Must be re-attached later on or the token refresh will only happen once
            axiosInstance.interceptors.response.eject(interceptor);

            originalRequest._retry = true;

            const accessToken =
              await this.oktaAuthClient.getOrRenewAccessToken();

            if (accessToken && originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              createAxiosResponseInterceptor();
              return axiosInstance(originalRequest);
            }

            // NOTE: Sign out if access token refresh was not successful
            // and original request was not retried
            await this.oktaAuthClient.signOut();
          }

          return Promise.reject(error);
        }
      );
    };

    createAxiosResponseInterceptor();
  }
}
