<template>
  <transition name="fade-out">
    <template v-if="showSpinner">
      <initial-loading-overlay />
    </template>
  </transition>
  <div>
    <component :is="layout" />
    <base-notification />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  watch,
  computed,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useRoute } from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import PublicLayout from '@/layouts/PublicLayout.vue';
import BaseNotification from '@/components/UI/BaseNotification.vue';
import { useCurrentAccountInfo } from '@/composable';
import { printVersionAndServicesStatus } from '@/utils/version';
import { auth } from '@/auth';
import { useUserInterfaceStore } from '@/store/modules/user-interface';
import { useAudienceStore } from '@/store/modules/audiences/audience-store';
import { useAuthStore } from '@/store/modules/auth';
import InitialLoadingOverlay from '@/components/UI/InitialLoadingOverlay.vue';
import {
  findSupportedLanguage,
  getUserLanguageFromLocalStorage,
  saveUserLanguageToLocalStorage,
} from '@/utils/language';

export default defineComponent({
  name: 'App',
  components: {
    InitialLoadingOverlay,
    MainLayout,
    AuthLayout,
    PublicLayout,
    BaseNotification,
  },
  setup() {
    const route = useRoute();
    const userInterfaceStore = useUserInterfaceStore();

    const audienceStore = useAudienceStore();
    const authStore = useAuthStore();

    onMounted(async () => {
      const loadingId = userInterfaceStore.setInitialLoading();
      await auth.waitForAuthIsReady();
      detectUserLanguage();
      userInterfaceStore.unsetInitialLoading(loadingId);
    });

    const detectUserLanguage = () => {
      const language = getUserLanguageFromLocalStorage(
        findSupportedLanguage(navigator.language)
      );
      saveUserLanguageToLocalStorage(language);
    };

    const showSpinner = computed(
      () => userInterfaceStore.isInitialLoading || !route.meta.layout
    );

    onBeforeMount(() => {
      auth.onUserChange(async (event, user) => {
        await authStore.handleUserChange(event, user);
      });
    });

    onBeforeUnmount(() => {
      userInterfaceStore.clearStoreRefreshInterval();
    });

    watch(
      () => route.meta.layout,
      () => {
        if (typeof route.meta.layout === 'string') {
          document.body.className = route.meta.layout.toLowerCase();
        }
      }
    );

    const { isAccountLoaded } = useCurrentAccountInfo();

    const shouldLoadInitialState = computed(() => isAccountLoaded.value);

    watch(shouldLoadInitialState, async (nextValue, previousValue) => {
      if (nextValue && previousValue !== nextValue) {
        await Promise.all([
          printVersionAndServicesStatus(),
          loadInitialState(),
        ]);

        userInterfaceStore.startStoreRefreshInterval();
      } else {
        userInterfaceStore.clearStoreRefreshInterval();
      }
    });

    const loadInitialState = async (): Promise<void> => {
      const loadingId = userInterfaceStore.setInitialLoading();

      await audienceStore.loadAudiences();

      userInterfaceStore.unsetInitialLoading(loadingId);
    };

    const layout = computed(() => route.meta.layout || 'AuthLayout');

    return {
      layout,
      showSpinner,
    };
  },
});
</script>

<style lang="scss">
@import '@/styles/global';
@import '@/styles/animations';

body {
  padding: 0;
  margin: 0;
}
body,
input,
textarea,
button {
  font-family: Inter, sans-serif;
}
</style>
