/**
 * Rounds a number to a specific number of decimal places.
 *
 * @param {number} number - The number to round.
 * @param {number} [numDecimalPlaces=2] - The number of decimal places.
 * @returns {number} - The rounded number.
 */
export const roundNumber = (number: number, numDecimalPlaces = 2): number => {
  const delimiter = 10 ** numDecimalPlaces;
  return Math.floor(number * delimiter) / delimiter;
};

/**
 * Rounds a number to a specified precision.
 *
 * @param {number} n - The number to round.
 * @param {number} [precision=1] - The number of decimal places.
 * @returns {number} The rounded number.
 */
export const round = (n: number, precision = 1): number => {
  const m = 10 ** precision;
  return Math.round(n * m) / m;
};

/**
 * Formats a large number with suffixes for thousands (k), millions (M), and billions (B).
 *
 * @param {number} number - The number to format.
 * @param {number} [numDecimalPlaces=2] - The number of decimal places.
 * @param {object} [config={}] - Suffixes for thousand, million, and billion.
 * @returns {string} - The formatted number.
 */
export const formatNumber = (
  number: number,
  numDecimalPlaces = 2,
  { millionSuffix = 'M', thousandSuffix = 'k', billionSuffix = 'B' } = {}
): string => {
  if (number < 1e-2 && number >= 1e-4) return number.toString().slice(0, 6);
  if (number < 1e-4 && number >= 1e-6) return number.toString().slice(0, 8);
  if (number < 1e-6 && number >= 1e-8) return number.toString().slice(0, 10);

  const integerNumber = parseInt(`${number}`, 10);
  const THOUSAND = 1000;
  const MILLION = 1000000;
  const BILLION = 1000000000;
  let delimiter = 1;
  let suffix = '';

  if (integerNumber >= THOUSAND && integerNumber < MILLION) {
    delimiter = THOUSAND;
    suffix = thousandSuffix;
  } else if (integerNumber >= MILLION && integerNumber < BILLION) {
    delimiter = MILLION;
    suffix = millionSuffix;
  } else if (integerNumber >= BILLION) {
    delimiter = BILLION;
    suffix = billionSuffix;
  }

  const formatted = getSplittedWithCommasNumber(
    roundNumber(number / delimiter, numDecimalPlaces).toString()
  );
  return `${formatted}${suffix}`;
};

/**
 * Splits a number by commas for easier readability.
 *
 * @param {string|number} input - The input number or string.
 * @returns {string} - The formatted string with commas.
 */
export const getSplittedWithCommasNumber = (input: string | number): string => {
  const splitted = String(input).split('.');
  splitted[0] = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return splitted.join('.');
};

/**
 * Converts null or undefined values to zero.
 *
 * @param {number|null|undefined} number - The number to convert.
 * @returns {number} - The converted number or 0.
 */
export const nullToZero = (number: number | null | undefined): number => {
  return number ?? 0;
};

/**
 * Formats a number as a percentage string.
 *
 * @param {number} number - The number to format.
 * @param {number} [maximumFractionDigits=0] - The maximum number of decimal places.
 * @returns {string} - The formatted percentage string.
 */
export const formatPercent = (
  number: number,
  maximumFractionDigits = 0
): string => {
  return (
    parseFloat(number.toString())
      .toFixed(maximumFractionDigits)
      .replace(/\.0+$/, '') + '%'
  );
};
