import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import {
  AdSize,
  Audience,
  AudienceDefinition,
  AudienceDefinitionType,
  AudienceScale,
  AudienceStatus,
  AudienceType,
  BuyerPlatform,
  BuyerPlatformType,
  CustomAudienceQueryItem,
  CustomAudienceQueryResults,
  Deal,
  DealMetric,
  DeviceType,
  Geography,
  MediaType,
} from 'shared-types';

import * as api from '@/store/api';
import {
  AudienceOption,
  GenerateAudienceOptionsInput,
  GetAudiencePlansResponse,
  getTagSuggestionsAndSimilarUrls,
  ImageStatus,
  Persona,
  PersonaImage,
} from '@/store/api';
import {
  copyDeep,
  DEFAULT_USER_COUNTRY,
  getUserCountryFromLocalStorage,
  groupBy,
  isIncludedInPeriod,
  logger,
} from '@/utils';
import { useAccountStore } from '@/store/modules/account/account-store';
import { RequestAborted } from '@/store/errors';
import { getUserLanguageFromLocalStorage } from '@/utils/language';

type DealsState = Record<string, Deal[]>;

interface DealForm {
  audienceId: string;
  accountId: string;
  demandPlatformSeatIds: {
    code: string;
    buyerPlatform: BuyerPlatform;
    buyerPlatformType: BuyerPlatformType;
  }[];
}

export interface DefinitionInput {
  value: string;
  type: AudienceDefinitionType;
}

export interface UpdateAudienceRequest {
  accountId: string;
  audienceId: string;
  userId?: string;
  mediaType?: MediaType;
  audienceMetric?: DealMetric;
  status?: AudienceStatus;
  definition?: {
    add: DefinitionInput[];
    remove: Omit<AudienceDefinition, 'scale' | 'clusterId'>[];
  };
}

export interface UpdateDealRequest {
  accountId: string;
  dealId: number;
  geography?: Geography[];
  adSizes?: AdSize[] | null;
  deviceTypes?: DeviceType[] | null;
  viewabilityRate?: number;
  videoCompletionRate?: number;
  floorPrice?: number;
}

export enum TableTabName {
  Audience = 'Audience',
  Plan = 'Plan',
}

const getUserAudiences = (audiences: Audience[]) => {
  return audiences.filter((item) => item.status !== AudienceStatus.DELETED);
};

export const useAudienceStore = defineStore('audience-store', () => {
  const audiences = ref<Audience[] | undefined>();
  const deals = ref<DealsState>({} as DealsState);
  const isAudiencesLoaded = ref<boolean>(false);
  const fetchAudiencesError = ref<undefined | string>(undefined);
  const isAudiencesMetricsLoaded = ref<boolean>(false);

  const accountStore = useAccountStore();

  type ObjectKey = keyof typeof deals.value;

  const createDeals = async (form: DealForm) => {
    try {
      const newDeals = await api.createDeals(form);

      const key = form.audienceId;

      if (!deals.value) {
        deals.value = {} as DealsState;
      }

      if (!deals.value[key]) {
        deals.value[key] = [];
      }

      const audienceDeals = deals.value[key] as Deal[];

      deals.value[key] = [...audienceDeals, ...newDeals];

      return newDeals;
    } catch (err) {
      logger.error(err);
      throw err;
    }
  };

  const updateDeal = async (payload: UpdateDealRequest, audienceId: string) => {
    await api.updateDeal(payload);

    if (!audiences.value || !deals.value) {
      throw new Error('Audience or Deal not loaded');
    }

    const {
      dealId,
      geography,
      adSizes,
      deviceTypes,
      viewabilityRate,
      videoCompletionRate,
      floorPrice,
    } = payload;
    const audienceIndex = audiences.value.findIndex(
      (audience) => audience.id === audienceId
    );

    const audienceKey = audienceId;
    const dealIndex = deals.value[audienceKey].findIndex(
      (deal) => deal.id === dealId
    );

    if (audienceIndex !== -1 && dealIndex !== -1) {
      if (geography) {
        deals.value[audienceKey][dealIndex].xandrGeography = geography;
      }

      if (adSizes) {
        deals.value[audienceKey][dealIndex].xandrAdSizes = adSizes;
      }

      if (deviceTypes) {
        deals.value[audienceKey][dealIndex].xandrDeviceTypes = deviceTypes;
      }

      if (viewabilityRate !== undefined) {
        deals.value[audienceKey][dealIndex].xandrViewabilityRate =
          viewabilityRate;
      }

      if (videoCompletionRate !== undefined) {
        deals.value[audienceKey][dealIndex].xandrVideoCompletionRate =
          videoCompletionRate;
      }

      if (floorPrice !== undefined) {
        deals.value[audienceKey][dealIndex].xandrFloorPrice = floorPrice;
      }
    } else {
      throw new Error(
        `Failed to update deal: Audience ${audienceId} or deal ${dealId} not found.`
      );
    }
  };

  const setAudienceDeals = async (audienceId: string) => {
    const account = useAccountStore().getAccount();

    const newDeals = await api.getAudienceDeals({
      accountId: account.id,
      audienceId,
    });

    const key = audienceId;
    if (!deals.value) {
      deals.value = {} as DealsState;
    }

    deals.value[key] = newDeals;
  };

  /**
   * Loads the audiences for the selected account.
   *
   * @param {Object} [options={}] - Options for loading audiences.
   * @param {boolean} [options.skipLoader=false] - Whether to skip the loading indicator.
   *  NOTE: This flag is needed here to prevent the appearance of the overlay loader
   *  when switching to the audience creation result screen during audience creation.
   * @returns {Promise<void>} A promise that resolves when the audiences are loaded.
   */
  const loadAudiences = async (
    options: { skipLoader?: boolean } = {}
  ): Promise<void> => {
    const { skipLoader = false } = options;

    try {
      if (!skipLoader) {
        isAudiencesLoaded.value = false;
      }

      fetchAudiencesError.value = undefined;

      const accountId = useAccountStore().getSelectedAccountId();

      if (accountId) {
        const newAudiences = await api.getAudiences({ accountId });
        audiences.value = newAudiences.filter((audience) => !audience.isHidden);
      }
    } catch (err) {
      logger.error(err);
      audiences.value = [];
      fetchAudiencesError.value = 'Failed to load audiences';
    } finally {
      isAudiencesLoaded.value = true;
    }
  };

  const unsetAudiences = () => {
    isAudiencesLoaded.value = false;
    fetchAudiencesError.value = undefined;
    audiences.value = undefined;
  };

  const setAudienceStatus = async (
    audienceId: string,
    status: AudienceStatus
  ) => {
    const account = useAccountStore().getAccount();

    if (!account.id || !status) {
      throw new Error(
        'Failed to set audience status: Missing account information.'
      );
    }

    const { id: accountId } = account;

    await api.updateAudienceStatus({
      accountId,
      audienceId,
      status,
    });

    if (audiences.value) {
      const audienceIndex = audiences.value.findIndex(
        (audience) => audience.id === audienceId
      );
      if (audienceIndex !== -1) {
        // NOTE: On BE we set notifiedInactiveAt to null, so we should do it on FE too
        audiences.value[audienceIndex].notifiedInactiveAt = null;
        audiences.value[audienceIndex].status = status;
      }
    }
  };

  const setAudienceScale = async (audienceId: string, scale: AudienceScale) => {
    const account = useAccountStore().getAccount();

    if (!account.id || !scale) {
      throw new Error(
        'Failed to set audience status: Missing account information.'
      );
    }

    const { id: accountId } = account;

    await api.updateAudienceScale({
      accountId,
      audienceId,
      scale,
    });
  };

  const deleteAudience = async (audienceId: string) => {
    await api.removeAudience(audienceId);

    if (audiences.value) {
      audiences.value = audiences.value.filter(
        (audience) => audience.id !== audienceId
      );
    }
  };

  const updateAudience = async (payload: UpdateAudienceRequest) => {
    await api.updateAudience(payload);

    if (audiences.value) {
      const { audienceId, status, userId, audienceMetric, mediaType } = payload;
      const audienceIndex = audiences.value.findIndex(
        (audience) => audience.id === audienceId
      );

      const key = audienceId;
      if (audienceIndex !== -1) {
        if (mediaType) {
          deals.value[key].forEach((d) => d.mediaType === mediaType);
        }

        if (audienceMetric) {
          deals.value[key].forEach((d) => (d.xandrMetric = audienceMetric));
        }

        if (status) {
          audiences.value[audienceIndex].status = status;
        }

        if (userId) {
          audiences.value[audienceIndex].userId = userId;
        }
      }
    }
  };

  const getAudienceDefinition = async (
    audienceId: string,
    accountId: string
  ) => {
    const audienceDefinition = await api.getAudienceDefinition(
      audienceId,
      accountId
    );
    return audienceDefinition;
  };

  const getAudienceDeals = (audienceId: string) => {
    const key = audienceId;
    if (!deals.value[key]) return [];

    return deals.value[key];
  };

  const getLiveAudienceCount = () => {
    if (!audiences.value) return 0;

    const liveAudiences = audiences.value.filter(
      (i) => i.status === AudienceStatus.LIVE
    );

    const analyticsByAudienceId = groupBy(liveAudiences, 'id');
    return Object.keys(analyticsByAudienceId).length;
  };

  const getAudienceById = (audienceId: string): Audience => {
    if (!audiences.value) {
      throw new Error('Audiences are not loaded');
    }
    const result = audiences.value.find((a) => {
      return a.id === audienceId;
    });

    if (!result) {
      logger.error(
        `Unable to find audience by id. Passed audience id: ${audienceId}.`
      );
      throw new Error(
        `Unable to find audience by id. Passed audience id: ${audienceId}.`
      );
    }

    return result;
  };

  const getAudiencesAnalyticsWithinDays = (period: number) => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    const result = getUserAudiences(audiences.value);

    return result.map((row) => {
      const statistics = row.statistics.filter((s) =>
        isIncludedInPeriod(period, s.day)
      );
      return {
        ...row,
        statistics,
      };
    });
  };

  const getAdminOwnAudiences = () => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    return audiences.value.filter((audience) => {
      return audience.type !== AudienceType.TAXONOMY;
    });
  };

  const getAudiences = () => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    return getUserAudiences(audiences.value);
  };

  const getTaxonomyAudiences = () => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    return getUserAudiences(audiences.value).filter(
      (audience) => audience.type == AudienceType.TAXONOMY
    );
  };

  const getDoesAudienceExist = (audienceId: string | string[]) => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    const audience = audiences.value.find((a) => {
      return a.id === audienceId;
    });
    return audience !== undefined;
  };

  const setAudienceOwner = async (audienceId: string, userId: string) => {
    const account = useAccountStore().getAccount();

    if (!account.id || !userId) {
      throw new Error(
        'Failed to update audience owner: Missing account information.'
      );
    }

    const { id: accountId } = account;
    await api.updateAudienceOwner({
      accountId,
      userId,
      audienceId,
    });

    if (audiences.value) {
      const audienceIndex = audiences.value.findIndex(
        (audience) => audience.id === audienceId
      );

      if (audienceIndex !== -1) {
        audiences.value[audienceIndex].userId = userId;
      } else {
        throw new Error(
          `Failed to update audience owner: Audience ${audienceId} not found.`
        );
      }
    }
  };

  const setAccountDeals = async () => {
    const accountId = useAccountStore().getSelectedAccountId();
    const accountDeals = await api.getAccountDeals(accountId);
    deals.value = {} as DealsState;

    for (const deal of accountDeals) {
      const audienceKey = deal.audienceId;
      if (!deals.value[audienceKey]) {
        deals.value[audienceKey] = [deal];
      } else {
        deals.value[audienceKey].push(deal);
      }
    }
  };

  const getAccountDeals = computed(() => {
    if (!deals.value) {
      throw new Error('Failed to get deals.');
    }

    const accountDeals: Deal[] = [];
    for (const [, deal] of Object.entries(deals.value)) {
      copyDeep(deal).forEach((item: Deal) => {
        accountDeals.push(item);
      });
    }

    return accountDeals;
  });

  const getAudienceStatus = computed(() => (audienceId: string) => {
    if (!audiences.value) throw new Error('Audiences are not loaded');

    const audience = audiences.value.find((a) => {
      return a.id === audienceId;
    });

    if (!audience) {
      logger.error(
        `Unable to find audience by id. Passed audience id: ${audienceId}.`
      );
      throw new Error(
        `Unable to find audience by id. Passed audience id: ${audienceId}.`
      );
    }

    return audience.status;
  });

  const audiencePlans = ref<GetAudiencePlansResponse[] | null>(null);
  const isAudiencePlansLoading = ref(false);

  const loadAudiencePlans = async () => {
    isAudiencePlansLoading.value = true;
    try {
      const account = useAccountStore().getAccount();
      audiencePlans.value = await api.getAudiencePlans(account.id);
    } finally {
      isAudiencePlansLoading.value = false;
    }
  };

  const deleteAudiencePlan = async ({
    id,
    plannedAudienceId,
  }: {
    id: string;
    plannedAudienceId: string;
  }) => {
    if (audiencePlans.value) {
      await api.deleteAudiencePlan({ id, plannedAudienceId });
      audiencePlans.value = audiencePlans.value.filter(
        (plan) => plan.id !== id
      );
    }
  };

  const resetAudiencePlans = () => {
    audiencePlans.value = null;
  };

  const isAudienceQueryResultsLoading = ref(false);
  const defineRequestAbortController = ref<AbortController | undefined>();

  const abortPreviousDefineRequest = () => {
    if (defineRequestAbortController.value) {
      defineRequestAbortController.value.abort();
    }
    defineRequestAbortController.value = new AbortController();
  };

  const audienceQueryResults = ref<CustomAudienceQueryResults | undefined>();
  const loadAudienceQueryResults = async (
    queries: CustomAudienceQueryItem[]
  ): Promise<CustomAudienceQueryResults | undefined> => {
    let requestCompletedOrFailed = false;

    abortPreviousDefineRequest();
    if (queries.length === 0) {
      audienceQueryResults.value = undefined;
      isAudienceQueryResultsLoading.value = false;
      return;
    }

    try {
      isAudienceQueryResultsLoading.value = true;
      // TODO: Provide real language and location for the query
      const response = await getTagSuggestionsAndSimilarUrls({
        accountId: useAccountStore().getSelectedAccountId(),
        queries: queries,
        language: getUserLanguageFromLocalStorage(),
        location: getUserCountryFromLocalStorage() ?? DEFAULT_USER_COUNTRY,
        abortSignal: defineRequestAbortController.value?.signal,
      });
      requestCompletedOrFailed = true;
      audienceQueryResults.value = response;
      return response;
    } catch (err) {
      if (!(err instanceof RequestAborted)) {
        requestCompletedOrFailed = true;
      }
    } finally {
      if (requestCompletedOrFailed) {
        isAudienceQueryResultsLoading.value = false;
      }
    }
  };

  const tableTabs = ref([
    {
      name: TableTabName.Audience,
      title: 'Audiences',
      visible: true,
    },
    {
      name: TableTabName.Plan,
      title: 'Plans',
      visible: false,
    },
  ]);

  const getDefinitionByType = <T>(type: AudienceDefinitionType) => {
    if (!audienceQueryResults.value) {
      return [] as T;
    }
    return audienceQueryResults.value.queries
      .filter((q) => q.type === type)
      .map((i) => {
        if (type === AudienceDefinitionType.SEARCH) {
          return { value: i.value, clusterId: i.clusterId };
        }
        return i.value;
      }) as T;
  };

  const clearAudienceQueryResults = () => {
    audienceQueryResults.value = undefined;
  };

  const isPersonaImageLoading = ref(false);

  const personaImage = ref<string | undefined>();

  const generatePersonaImage = async (persona: Persona) => {
    const accountId = accountStore.getSelectedAccountId();
    isPersonaImageLoading.value = true;

    try {
      personaImage.value = await api.generatePersonaImage(persona, accountId);
      return personaImage.value;
    } finally {
      isPersonaImageLoading.value = false;
    }
  };

  const audienceOptions = ref(new Map<string, AudienceOption[]>());
  const audienceOptionsLoading = ref(new Map<string, boolean>());
  const selectedAudienceOptions = ref<AudienceOption[]>([]);
  const isSelectedAudienceOptionsLoading = computed(() => {
    const key = getKeyForSelectedPersona();
    return audienceOptionsLoading.value.get(key);
  });

  const cachedAudienceOptions = ref(new Map<string, AudienceOption[]>());

  const setSelectedAudienceOptions = (key: string) => {
    selectedAudienceOptions.value = audienceOptions.value.get(key) ?? [];
  };

  let abortController: AbortController | null = null;

  const generateAudienceOptions = async (
    input: GenerateAudienceOptionsInput,
    caching = false,
    useAbortController = true
  ): Promise<AudienceOption[] | undefined> => {
    const cacheKey = getKeyForPersona(input.persona);

    const cachedAudienceOptionsByKey =
      cachedAudienceOptions.value.get(cacheKey);

    if (caching && input.persona && cachedAudienceOptionsByKey) {
      audienceOptions.value.set(cacheKey, cachedAudienceOptionsByKey);

      const selectedPersonaKey = getKeyForSelectedPersona();
      if (selectedPersonaKey === cacheKey) {
        selectedAudienceOptions.value = cachedAudienceOptionsByKey;
      }
      return cachedAudienceOptionsByKey;
    }

    let requestCompletedOrFailed = false;

    if (useAbortController) {
      if (abortController) {
        abortController.abort();
      }

      abortController = new AbortController();
    }

    audienceOptionsLoading.value.set(cacheKey, true);
    try {
      const accountId = accountStore.getSelectedAccountId();
      const options = await api.generateAudienceOptions(
        input,
        accountId,
        abortController ? abortController.signal : undefined
      );

      requestCompletedOrFailed = true;
      audienceOptions.value.set(cacheKey, options);

      const selectedPersonaKey = getKeyForSelectedPersona();
      if (selectedPersonaKey === cacheKey) {
        selectedAudienceOptions.value = options;
      }
      if (caching) {
        cachedAudienceOptions.value.set(cacheKey, options);
      }

      return options;
    } catch (err) {
      if (!(err instanceof RequestAborted)) {
        requestCompletedOrFailed = true;
        throw err;
      }
    } finally {
      if (requestCompletedOrFailed) {
        audienceOptionsLoading.value.set(cacheKey, false);
        abortController = null;
      }
    }
  };

  const resetAudienceOptions = () => {
    audienceOptions.value.clear();
    selectedAudienceOptions.value = [];
    cachedAudienceOptions.value.clear();
  };

  const personas = ref<Persona[] | undefined>();
  const selectedPersonaIdx = ref<number | undefined>();

  const setSelectedPersonaIdx = (idx: number) => {
    if (personas.value) {
      selectedPersonaIdx.value = idx;
    }
  };
  const getSelectedPersonaAndImage = () => {
    if (
      personaImages.value &&
      personas.value &&
      selectedPersonaIdx.value !== undefined
    ) {
      return {
        ...personas.value[selectedPersonaIdx.value],
        img: personaImages.value[selectedPersonaIdx.value].url,
      };
    }
  };

  const getSelectedPersona = () => {
    const selectedPersonaAndImage = getSelectedPersonaAndImage();

    if (selectedPersonaAndImage) {
      const { img, ...personaWithoutImage } = selectedPersonaAndImage;
      return personaWithoutImage;
    }
  };

  const getKeyForPersona = (persona: Persona | undefined) =>
    JSON.stringify(persona);

  const getKeyForSelectedPersona = () => {
    const selectedPersona = getSelectedPersona();
    return getKeyForPersona(selectedPersona);
  };

  const isPersonasLoading = ref(false);

  const generatePersonas = async (userInput: string): Promise<Persona[]> => {
    isPersonasLoading.value = true;

    try {
      const accountId = accountStore.getSelectedAccountId();
      personas.value = await api.generatePersonas({
        userInput,
        accountId,
        country: getUserCountryFromLocalStorage() ?? undefined,
        count: 3,
      });

      setSelectedPersonaIdx(0);
      return personas.value;
    } finally {
      isPersonasLoading.value = false;
    }
  };

  const personaImages = ref<PersonaImage[] | undefined>();

  const generatePersonaImages = () => {
    const accountId = accountStore.getSelectedAccountId();

    if (personas.value) {
      personaImages.value = personas.value.map(() => ({
        url: null,
        status: ImageStatus.LOADING,
      }));

      personas.value.forEach(async (persona, idx) => {
        try {
          const image = await api.generatePersonaImage(persona, accountId);
          if (personaImages.value) {
            personaImages.value[idx].url = image;
            personaImages.value[idx].status = ImageStatus.SUCCESS;
          }
        } catch (error) {
          if (personaImages.value) {
            personaImages.value[idx].status = ImageStatus.ERROR;
          }
          logger.error(
            `Failed to load image for persona ${persona.name}:`,
            error
          );
        }
      });
    }
  };

  const resetPersonas = () => {
    personas.value = undefined;
  };
  const isAudienceNavHeaderVisible = ref(false);

  const setIsAudienceNavHeaderVisible = (visible: boolean) => {
    isAudienceNavHeaderVisible.value = visible;
  };

  return {
    audiences,
    deals,
    isAudiencesLoaded,
    fetchAudiencesError,
    isAudiencesMetricsLoaded,
    createDeals,
    updateDeal,
    setAudienceDeals,
    setAccountDeals,
    getAccountDeals,
    loadAudiences,
    setAudienceStatus,
    setAudienceScale,
    setAudienceOwner,
    deleteAudience,
    updateAudience,
    getAudienceDefinition,
    getAudienceDeals,
    getAudienceById,
    getLiveAudienceCount,
    getAudiencesAnalyticsWithinDays,
    getAdminOwnAudiences,
    unsetAudiences,
    getAudiences,
    getDoesAudienceExist,
    getAudienceStatus,
    getTaxonomyAudiences,
    loadAudienceQueryResults,
    isAudienceQueryResultsLoading,
    audienceQueryResults,
    audiencePlans,
    loadAudiencePlans,
    deleteAudiencePlan,
    tableTabs,
    isAudiencePlansLoading,
    resetAudiencePlans,
    clearAudienceQueryResults,
    getDefinitionByType,
    generatePersonas,
    personas,
    isPersonasLoading,
    resetPersonas,
    generateAudienceOptions,
    isPersonaImageLoading,
    personaImage,
    generatePersonaImage,
    resetAudienceOptions,
    isAudienceNavHeaderVisible,
    setIsAudienceNavHeaderVisible,
    selectedPersonaIdx,
    setSelectedPersonaIdx,
    generatePersonaImages,
    personaImages,
    getSelectedPersonaAndImage,
    getSelectedPersona,
    selectedAudienceOptions,
    setSelectedAudienceOptions,
    getKeyForPersona,
    getKeyForSelectedPersona,
    isSelectedAudienceOptionsLoading,
  };
});
