<template>
  <div
    class="small-screen-banner"
    :class="{ 'small-screen-banner--manually-closed': isClosed }"
  >
    <span
      class="small-screen-banner__top-icon"
      @click="closeModal"
    >
      <xmark-icon />
    </span>

    <div class="small-screen-banner__content">
      <desktop-icon />
      <h2 class="small-screen-banner__title">
        Consider using desktop for better experience
      </h2>
      <p class="small-screen-banner__subtitle">
        Currently we do not fully support small screen sizes, click proceed to
        continue using a scaled down version of the application.
      </p>
      <base-button
        :theme="ButtonThemes.WHITE_FLAT"
        :size="ButtonSizes.MEDIUM"
        full-width
        class="small-screen-banner__submit-action"
        @click="closeModal"
      >
        Proceed
      </base-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import DesktopIcon from '@/assets/icons/desktop.svg';
import XmarkIcon from '@/assets/icons/xmark.svg';
import { BaseButton, ButtonThemes, ButtonSizes } from '@airgrid/components';
import { useUserInterfaceStore } from '@/store/modules/user-interface';

export default defineComponent({
  name: 'SmallScreenBanner',
  components: {
    DesktopIcon,
    XmarkIcon,
    BaseButton,
  },
  setup() {
    const userInterfaceStore = useUserInterfaceStore();

    const isClosed = computed(() => userInterfaceStore.smallScreenBannerClosed);

    const closeModal = () => {
      userInterfaceStore.toggleSmallScreenBannerClosed(true);
    };

    return {
      ButtonThemes,
      ButtonSizes,
      closeModal,
      isClosed,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define small-screen-banner

@import '@airgrid/components/styles/shared/typography';
@import '@/styles/breakpoints';

$blue-background: #394873;

.small-screen-banner {
  display: none;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1002;

  background-color: $blue-background;
  overflow: hidden;

  color: #fff;

  &.small-screen-banner--manually-closed {
    display: none;
  }

  @media screen and (max-width: $breakpoint-big-tablet) {
    display: flex;
  }

  .small-screen-banner__content {
    flex: 5;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    max-width: 295px;

    svg {
      width: 55px;
      height: 46px;
      margin: 40px auto 20px;
      opacity: 0.8;
    }

    h2 {
      max-width: 295px;
      margin: 0 auto 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #fff;
    }

    p {
      max-width: 295px;
      margin: 0 auto 32px;
      color: rgba(#fff, 0.8);
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.small-screen-banner__title {
  @extend %heading-2;
}
.small-screen-banner__subtitle {
  @extend %paragraph;
}
.small-screen-banner__submit-action {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 56px;
  width: 50%;
  max-width: 143px;
  color: var(--color-primary-blue);
}
.small-screen-banner__top-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.5;
  cursor: pointer;
  color: var(--color-blacks-white);

  svg {
    width: 24px;
  }
}
</style>
