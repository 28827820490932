import _merge from 'lodash/merge';
import _sortBy from 'lodash/sortBy';

/**
 * Performs a deep merge of multiple objects, combining properties recursively.
 * If properties are arrays, it concatenates them.
 *
 * @param {...Record<string, unknown>} objects - The objects to merge.
 * @returns {Record<string, unknown>} - A new object representing the deeply merged result.
 */
export const mergeDeep = (
  ...objects: Record<string, unknown>[]
): Record<string, unknown> => {
  const isObject = (obj: unknown) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(
          pVal as Record<string, unknown>,
          oVal as Record<string, unknown>
        );
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};

/**
 * Creates a deep copy of an object.
 *
 * @template T
 * @param {T} obj - The object to deep copy.
 * @returns {T} - A new object that is a deep copy of the input.
 */
export const copyDeep = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

/**
 * Sorts an array of objects by a specified key.
 *
 * @template T
 * @param {T[]} arr - The array of objects to sort.
 * @param {keyof T} key - The key to sort the objects by.
 * @returns {T[]} - A new array of objects, sorted by the specified key.
 */
export const sortByKey = <T>(arr: T[], key: keyof T): T[] => {
  return _sortBy(arr, [key]);
};

/**
 * Deeply merges two or more objects using lodash's merge function.
 *
 * @param {...any[]} args - The objects to merge.
 * @returns {any} - The merged object.
 */
export const merge = _merge;
