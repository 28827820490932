import { BuyerPlatform } from 'shared-types';
import { PixelType } from '@/store/modules/measure/measure-store';
import { apiClient, isAxiosRequestAbortedError } from '@/store/api/client';
import { RequestAborted } from '@/store/errors';

export interface CreateAttributionPixelGroupInput {
  accountId: string;
  name: string;
  attributionDestination: string;
  expiryInDays: number;
  eventWindowInDays: null | number;
  activationDsps: string[];
  pixels: AttributionPixel[];
  reportConfigurations: AggregatedReportConfiguration[];
}

export enum PixelEvent {
  SOURCE = 'SOURCE',
  TRIGGER = 'TRIGGER',
}

export interface BasePixel {
  name: string;
  htmlPixel: string;
  activationDsps?: BuyerPlatform;
}

export type SourcePixel = BasePixel & {
  event: PixelEvent.SOURCE;
};

export type TriggerPixel = BasePixel & {
  event: PixelEvent.TRIGGER;
  gtmPixel: string;
  priority: number;
  type: PixelType;
};

export interface CreateAttributionPixelGroupResponse {
  id: number;
  name: string;
  attributionDestination: string;
  expiryInDays: number;
  eventWindowInDays: null | number;
  activationDsps: BuyerPlatform[];
  userId: string;
  pixels: (SourcePixel | TriggerPixel)[];
  sourceLoadsCount: number;
  triggerLoadsCount: number;
  reportConfigurations: AggregatedReportConfiguration[];
}

export interface AttributionPixel {
  name: string;
  priority: number;
  type: string;
}

export const createAttributionPixelGroup = async (
  input: CreateAttributionPixelGroupInput
): Promise<CreateAttributionPixelGroupResponse> => {
  const { data } = await apiClient.post<CreateAttributionPixelGroupResponse>({
    url: '/measure/create-attribution-pixel-group',
    data: input,
  });

  return data;
};

export type GetAttributionPixelGroupResponse =
  CreateAttributionPixelGroupResponse & {
    audiences?: { id: string; name: string }[];
    reportConfigurations: AggregatedReportConfiguration[];
  };

export const getAttributionPixelGroups = async (
  accountId: string,
  abortSignal?: AbortSignal
): Promise<GetAttributionPixelGroupResponse[]> => {
  try {
    const { data } = await apiClient.get({
      url: '/measure/attribution-pixel-groups',
      options: {
        params: {
          accountId,
        },
        signal: abortSignal,
      },
    });
    return data.result;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export const updateAttributionPixelGroupOwner = async ({
  attributionPixelGroupId,
  newOwnerId,
  accountId,
}: {
  attributionPixelGroupId: number;
  newOwnerId: string;
  accountId: string;
}) => {
  await apiClient.patch({
    url: '/measure/attribution-pixel-groups',
    data: {
      attributionPixelGroupId,
      newOwnerId,
      accountId,
    },
  });
};

export interface AttributionPixelGroupTriggerReport {
  day: string;
  cnt: number;
  attributionPixelId: number;
  attributionPixelType: string;
  attributionPixelName: string;
}

export interface AttributionPixelGroupSourceAndConversionReport {
  day: string;
  cnt: number;
  dsp: string;
}

export interface AttributionPixelGroupAraSupportedSourceEventsReport {
  day: string;
  percentage: number;
  dsp: string;
}

export interface GetAttributionPixelGroupAnalyticsResponse {
  triggers: AttributionPixelGroupTriggerReport[];
  sources: AttributionPixelGroupSourceAndConversionReport[];
  conversions: AttributionPixelGroupSourceAndConversionReport[];
  araSupportedSourceEvents: AttributionPixelGroupAraSupportedSourceEventsReport[];
}

export const getAttributionPixelGroupAnalytics = async (
  attributionPixelGroupId: number
): Promise<GetAttributionPixelGroupAnalyticsResponse> => {
  const { data } = await apiClient.get<{
    result: GetAttributionPixelGroupAnalyticsResponse;
  }>({
    url: 'reports/attribution-pixel-group',
    options: {
      params: {
        attributionPixelGroupId,
      },
    },
  });

  return data.result;
};

export interface AggregatedReportDimension {
  name: string;
  id: number;
  dsp: BuyerPlatform | null;
  shortCode: string | null;
}

export interface AggregatedReportConfiguration {
  id?: number;
  dimensions: AggregatedReportDimension[];
}

export interface GetAttributionPixelGroupAggregatedReportDimensionsResponse {
  dimensions: AggregatedReportDimension[];
  defaultReportConfigurations: AggregatedReportConfiguration[];
}

export const getAttributionPixelGroupAggregatedReportDimensions = async (
  accountId: string
) => {
  const { data } = await apiClient.get<{
    result: GetAttributionPixelGroupAggregatedReportDimensionsResponse;
  }>({
    url: `/measure/attribution-pixel-groups-aggregated-report-dimensions`,
    options: {
      params: {
        accountId,
      },
    },
  });

  return data.result;
};

export interface AttributionPixelGroupAggregateReport {
  id: number;
  [key: string]: number | string;
  attributionPixelGroupId: number;
  conversions: number;
  impressions: number;
  cvr: number;
}

export type AttributionPixelGroupAggregateReportsResponse = {
  id: number;
  rows: AttributionPixelGroupAggregateReport[];
}[];

export interface GetAttributionPixelGroupAggregateReportsRequest {
  attributionPixelGroupId: number;
  exportedReportId?: number;
}

export const getAttributionPixelGroupAggregateReports = async (
  input: GetAttributionPixelGroupAggregateReportsRequest
): Promise<AttributionPixelGroupAggregateReportsResponse> => {
  const { data } = await apiClient.get<{
    result: AttributionPixelGroupAggregateReportsResponse;
  }>({
    url: 'reports/attribution-pixel-group-aggregate',
    options: {
      params: input,
    },
  });

  return data.result;
};

export interface GetAttributionPixelGroupDebugReportRequest {
  attributionPixelGroupId: number;
  attributionDestination: string;
}

export interface AttributionPixelGroupDebugReport {
  attributionPixelGroupId: number;
  attributionDestination: string;
  cnt: number;
  reason: string;
  explanation: string;
}

export interface GetAttributionPixelGroupDebugReportResponse {
  reportById: AttributionPixelGroupDebugReport[];
  reportByDestination: AttributionPixelGroupDebugReport[];
}

export const getAttributionPixelGroupDebugReport = async (
  input: GetAttributionPixelGroupDebugReportRequest
) => {
  const { data } = await apiClient.get<{
    result: GetAttributionPixelGroupDebugReportResponse;
  }>({
    url: '/reports/attribution-pixel-group-debug',
    options: {
      params: input,
    },
  });

  return data.result;
};
