<template>
  <nav class="top-bar">
    <div class="top-bar__logo">
      <Logo />
    </div>
    <template v-if="isUserLoaded && isDashboard && isVisible">
      <div class="top-bar__right">
        <search-input
          ref="searchInputElement"
          readonly
          small
          placeholder="Search (/) for audiences, pixels, pages and more"
          data-testid="open-search-modal-input"
          @click="openSearchPopup"
        />
        <docs-link class="nav-bar__docs-link" />
        <div
          class="top-bar__account"
          data-testid="top-bar-account"
        >
          <nav-bar-drop-down />
        </div>
      </div>
    </template>
  </nav>
  <template v-if="isVisible">
    <search-modal
      :show="isModalOpened"
      @[SearchModalEvents.CLOSE]="isModalOpened = false"
    />
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue';
import { useRoute } from 'vue-router';
import { routes } from '@/router/routes';
import { useCurrentAccountInfo, useCurrentUserInfo } from '@/composable';

import Logo from '@/assets/icons/logo.svg';
import { useUserInterfaceStore } from '@/store/modules/user-interface';
import NavBarDropDown from '@/components/UI/AccountDropDown.vue';
import { DocsLink, SearchInput } from '@airgrid/components';
import SearchModal, {
  SearchModalEvents,
} from '@/components/UI/Layout/SearchModal/SearchModal.vue';
import { MixpanelEvent, tracking } from '@/utils/tracking';

export default defineComponent({
  name: 'AppHeader',
  components: {
    SearchModal,
    SearchInput,
    DocsLink,
    NavBarDropDown,
    Logo,
  },
  setup() {
    const { isUserLoaded, isAdmin } = useCurrentUserInfo();
    const { isAccountLoaded } = useCurrentAccountInfo();
    const userInterfaceStore = useUserInterfaceStore();
    const isModalOpened = ref(false);

    const showNav = computed(() => {
      return userInterfaceStore.showNavbar;
    });

    const route = useRoute();
    // TODO: do we need this?
    const isDashboard = computed(() => route.meta.auth);

    const isVisible = computed(() => showNav.value && isAccountLoaded.value);

    const openSearchPopup = () => {
      tracking.track(MixpanelEvent.SEARCH_MODAL_OPENED);
      isModalOpened.value = true;
    };

    const onKey = (event: KeyboardEvent) => {
      // NOTE: We need to prevent catching keyup (slash) event
      // if user focused in input to allow user to type a
      // url format text (example: https://domain.com)
      if (document.activeElement) {
        if (['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName))
          return;
      }

      const SLASH_KEY_CODE = 'Slash';
      if (event.code === SLASH_KEY_CODE && !isModalOpened.value) {
        openSearchPopup();
      }
    };

    window.addEventListener('keyup', onKey);
    onBeforeUnmount(() => {
      window.removeEventListener('keyup', onKey);
    });

    return {
      routes,
      isDashboard,
      isUserLoaded,
      showNav,
      isAdmin,
      isAccountLoaded,
      isVisible,
      openSearchPopup,
      isModalOpened,
      SearchModalEvents,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define top-bar

@import '@airgrid/components/styles/shared/typography';

.top-bar {
  top: 0;
  z-index: var(--z-bar);
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  height: var(--global-top-bar-height);
  background-color: var(--color-blacks-white);
  gap: 16px;
  border-bottom: 1px solid var(--color-blacks-input-back-grey);

  &__right {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}

// todo: this vs v-show
.top-bar--hidden {
  visibility: hidden;
}

.top-bar__logo {
  display: flex;

  svg {
    width: 32px;
  }
}

.top-bar__account {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color-blacks-grey);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-bar__docs-link {
  color: var(--color-blacks-grey);
  margin-left: 0;

  &:hover {
    color: var(--color-primary-blue);
  }
}

:deep(.input-field__input) {
  width: 380px;
  cursor: text;
}

:deep(.search__icon) {
  left: 12px;
}
</style>
