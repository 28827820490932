<template>
  <router-link
    data-testid="create-audience-button"
    class="open-audience-creation-button"
    :class="{ 'open-audience-creation-button--title-shown': showTitle }"
    :to="{
      name: routes.createAudience.name,
    }"
  >
    <plus-icon />
    <template v-if="showTitle">
      Create
    </template>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PlusIcon from '@/assets/icons/plus.svg';
import { routes } from '@/router/routes';
export default defineComponent({
  name: 'OpenAudienceCreationButton',
  components: { PlusIcon },
  props: {
    showTitle: Boolean,
  },
  setup() {
    return {
      routes,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define open-audience-creation-button
@import '@airgrid/components/styles/shared/typography';

.open-audience-creation-button {
  width: 32px;
  height: 32px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-blue);
  border-radius: var(--border-radius-element);

  @extend %generic-text-styles;
  text-decoration: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--color-white);

  white-space: nowrap;

  transition: width var(--transition-default);

  &.open-audience-creation-button--title-shown {
    width: 94px;
  }

  &:hover {
    background-color: var(--color-dark-blue);
  }

  svg {
    width: 12px;
    height: 12px;
  }
}
</style>
