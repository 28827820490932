import { defineStore } from 'pinia';
import * as api from '@/store/api';
import { setUserToSentry } from '@/utils/sentry';
import { logger, saveUserCountryToLocalStorage } from '@/utils';
import { useAccountStore } from '@/store/modules/account/account-store';
import { MixpanelSignInEventType, tracking } from '@/utils/tracking';
import { useAdminStore } from '@/store/modules/admin/admin-store';
import {
  NotificationType,
  useUserInterfaceStore,
} from '@/store/modules/user-interface';
import { routes } from '@/router/routes';
import { auth as authProvider, OnUserChangeCallbackEventType } from '@/auth';
import { router, RouteType } from '@/router/router';
import { useAudienceStore } from '@/store/modules/audiences/audience-store';
import { Permission, UserInfo } from 'shared-types';
import { useAnalyticsStore } from '@/store/modules/analytics/analytics-store';
import {
  getLastAccessedAccount,
  removeLastAccessedAccount,
} from '@/utils/navigation';

export const useAuthStore = defineStore('auth-store', () => {
  const adminStore = useAdminStore();
  const audienceStore = useAudienceStore();
  const accountStore = useAccountStore();
  const userInterfaceStore = useUserInterfaceStore();
  const analyticsStore = useAnalyticsStore();

  const loadCurrentUser = async ({
    user,
    isLogin,
  }: {
    user: UserInfo;
    isLogin: boolean;
  }) => {
    await router.isReady();
    const isAuthFlow =
      router.currentRoute.value.meta.routeType === RouteType.AUTH;
    const isDashboard =
      router.currentRoute.value.meta.routeType === RouteType.DASHBOARD;
    const isPublic =
      router.currentRoute.value.meta.routeType === RouteType.PUBLIC;

    try {
      accountStore.setUser(user);
      saveUserCountryToLocalStorage(user.country);

      tracking.setUser(user.id, {
        $email: user.email,
        $name: user.name,
      });

      if (user.isAdmin) {
        if (!adminStore.isAdminAccountsLoaded) {
          try {
            await adminStore.setAdminAccounts();
          } catch (err) {
            userInterfaceStore.showNotification({
              message: 'Unexpected error',
              type: NotificationType.ERROR,
            });
          }
        }

        const lastAccessedAccount = getLastAccessedAccount();

        if (lastAccessedAccount) {
          await accountStore.setSelectedAccountId(lastAccessedAccount);
          if (!isDashboard && !isPublic) {
            return routes.overview;
          }
        } else {
          return routes.selectAccount;
        }
      } else if (user.accountId) {
        let accountId = user.accountId;
        const lastAccessedAccount = getLastAccessedAccount();
        if (
          user.permissions &&
          user.permissions.includes(Permission.ACCOUNT_ACCESS_ALL) &&
          lastAccessedAccount
        ) {
          accountId = lastAccessedAccount;
        }
        tracking.setAccount(user.id, {
          accountId: accountId,
        });
        await accountStore.setSelectedAccountId(accountId);
      } else {
        throw new Error('User is not assigned to any account');
      }

      if (isLogin) {
        const isAccountLoaded = accountStore.isAccountLoaded;
        if (isAccountLoaded) {
          const account = accountStore.getAccount();

          tracking.setAccount(user.id, {
            accountId: account.id,
            accountName: account.name,
            isAdmin: user.isAdmin,
          });

          tracking.registerSignIn({
            type: MixpanelSignInEventType.EMAIL,
            email: user.email,
            isAdmin: user.isAdmin,
            accountName: account.name,
          });

          if (!isAuthFlow && !isDashboard && !isPublic) {
            return routes.overview;
          }
        }
      }
    } catch (err) {
      logger.error(err);
      await authProvider.signOut();
      return routes.login;
    }
  };

  const updateUserPassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    await api.resetPassword(oldPassword, newPassword);
  };

  const updateEmail = async (email: string) => {
    await api.updateEmail(email);
    setUserToSentry({ email });
  };

  const handleUserChange = async (
    event: OnUserChangeCallbackEventType,
    user: UserInfo | null
  ) => {
    if (user === null) {
      if (event === OnUserChangeCallbackEventType.SESSION_EXPIRED) {
        userInterfaceStore.showNotification({
          message: 'Your session has expired. Please login again to continue.',
          type: NotificationType.ERROR,
        });
      }

      if (event === OnUserChangeCallbackEventType.NETWORK_ERROR) {
        userInterfaceStore.showNotification({
          message:
            'There was a network error. Please check your internet connection and login again.',
          type: NotificationType.ERROR,
        });
      }

      if (event === OnUserChangeCallbackEventType.UNEXPECTED_ERROR) {
        userInterfaceStore.showNotification({
          message:
            'A call to retrieve account information has failed, please login again.',
          type: NotificationType.ERROR,
        });
      }

      if (
        [
          OnUserChangeCallbackEventType.SESSION_EXPIRED,
          OnUserChangeCallbackEventType.NETWORK_ERROR,
          OnUserChangeCallbackEventType.UNEXPECTED_ERROR,
          OnUserChangeCallbackEventType.SIGNED_OUT,
        ].includes(event)
      ) {
        await router.push({
          ...routes.login,
          query: { error_code: event },
          force: true,
        });
      }

      await authProvider.waitForAuthStateChange().then(() => {
        accountStore.setUser(undefined);
        adminStore.unsetAdminAccounts();
        accountStore.unsetAccount();
        audienceStore.unsetAudiences();
        analyticsStore.unsetAnalytics();
      });
    } else {
      const next = await loadCurrentUser({
        user,
        isLogin: event === OnUserChangeCallbackEventType.SIGNED_IN,
      });

      if (next) {
        await router.push(next);
      }
    }
  };

  const signOut = async () => {
    removeLastAccessedAccount();
    await authProvider.signOut();
  };

  return {
    loadCurrentUser,
    updateEmail,
    updateUserPassword,
    handleUserChange,
    signOut,
  };
});
