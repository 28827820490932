import { NavigationGuard } from 'vue-router';
import { routes } from './routes';
import { auth } from '@/auth';
import { RouteType } from '@/router/router';

export const onlyProgrammaticallyAllowed: NavigationGuard = (
  _to,
  from,
  next
) => {
  if (from.name === null || from.name === undefined) {
    next(routes.overview);
  } else {
    next();
  }
};

export const onlyInstantlyAuthorized: NavigationGuard = async (
  _to,
  _from,
  next
) => {
  const user = await auth.getUser();
  if (user) {
    next();
  } else {
    next(routes.login);
  }
};

export const onlyAccountMembers: NavigationGuard = async (_to, _from, next) => {
  const user = await auth.getUser();
  if (_from.meta.routeType === RouteType.PUBLIC) {
    next();
  } else if (!user) {
    next(routes.login);
  } else {
    if (!user.accountId && !user.isAdmin) {
      next(routes.login);
    } else {
      next();
    }
  }
};

export const onlyUnauthenticated: NavigationGuard = async (
  _to,
  _from,
  next
) => {
  const user = await auth.getUser();
  if (!user) {
    next();
  } else {
    if (!user.isAdmin && user.accountId === null) {
      next(routes.login);
    } else {
      next(routes.overview);
    }
  }
};
