import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import {
  Auth,
  AuthNetworkError,
  AuthTokenExpired,
  OnUserChangeCallback,
  OnUserChangeCallbackEventType,
} from '@/auth';
import { UserInfo } from 'shared-types';

import {
  apiClient,
  isAxiosError,
  noRetryClient,
  retryClient,
} from '@/store/api/client';
import { UnexpectedError } from '@/store/errors';
import { logger } from '@/utils';
import { oktaAuthClient } from '@/utils/okta';
import { OktaAxiosInterceptor } from '@/auth/implementations/okta-axios-interceptor';

type OnAuthStateChangeCallback = (authState: AuthState | null) => Promise<void>;

export class OktaAuthClient implements Auth {
  private client: OktaAuth;
  private user: UserInfo | null = null;
  private isAuthReady = false;
  private waitForAuthIsReadyCallbacks: ((
    value: void | PromiseLike<void>
  ) => void)[] = [];
  private accessToken: string | null = null;
  private onUserChangeCallbacks: OnUserChangeCallback[] = [];
  private authStateChangeCallback: OnAuthStateChangeCallback | undefined =
    undefined;

  constructor() {
    this.client = oktaAuthClient;
  }

  /**
   * Signs out the current user.
   *
   * It resets current client state by resetting access token and user.
   * Also it stops internal Okta client and unsubscribes from auth state events.
   * Eventually it calls the Okta client 'signOut' method that results in a redirect
   * to Okta domain and then back to Studio login page.
   *
   * @returns {Promise<void>} Resolves when the sign-out process is complete.
   */
  async signOut(): Promise<void> {
    if (this.accessToken !== null || this.user !== null) {
      this.setAccessToken(null);
      this.user = null;
      await this.client.stop();
      if (this.authStateChangeCallback !== undefined) {
        this.client.authStateManager.unsubscribe(this.authStateChangeCallback);
        this.authStateChangeCallback = undefined;
      }
      await this.client.signOut({
        clearTokensBeforeRedirect: true,
      });
    }
  }

  /**
   * Returns details for currently logged in user, or `null` if no user is logged in.
   *
   * Internally it calls the `waitForAuthIsReady` method to wait until authentication flow is complete.
   *
   * @returns {Promise<UserInfo|null>} Resolves to the user's information if logged in, otherwise null.
   * @see {waitForAuthIsReady}
   */
  async getUser(): Promise<UserInfo | null> {
    await this.waitForAuthIsReady();
    return this.user;
  }

  /**
   * Asynchronously reloads current user and refreshes access tokens if needed.
   *
   * This method triggers update auth state using Okta auth state manager and user
   * is reloaded implicitly once auth state event is triggered by Okta and processed
   * by auth state listener.
   *
   * See registerOnAuthStateChange method with auth state listener defined.
   *
   * This method fetches and updates the user information from the backend.
   *
   * @async
   * @returns {Promise<void>} Resolves when the user data has been successfully reloaded.
   */
  async reloadUser(): Promise<void> {
    await this.client.authStateManager.updateAuthState();
  }

  /**
   * Returns the access token for current user session or `null` if not authenticated.
   *
   * @returns {string | null} The access token if user session is opened, or `null` otherwise.
   */
  getAccessToken(): string | null {
    return this.client.getAccessToken() ?? null;
  }

  /**
   * Asynchronously refreshes the access token and returns a new token as a result.
   *
   * @returns {Promise<string|null>} A Promise that resolves to the new access token if successful,
   *                            or null if there was an error during token refresh.
   */
  async refreshAccessToken(): Promise<string | null> {
    const accessToken = await this.client.getOrRenewAccessToken();
    this.setAccessToken(accessToken);
    return accessToken;
  }

  /**
   * Registers a callback function to be invoked when the user state changes.
   *
   * It could be signed in or signed out events. It also supports events like session expired or
   * network error when refreshing user details.
   *
   * @param {OnUserChangeCallback} callback - The callback function to be invoked.
   * @see {OnUserChangeCallbackEventType}
   */
  onUserChange(callback: OnUserChangeCallback): void {
    const index = this.onUserChangeCallbacks.indexOf(callback);
    if (index === -1) {
      this.onUserChangeCallbacks.push(callback);
    }
  }

  /**
   * Initializes the authentication provider and mounts the Vue app when complete.
   *
   * This is very important method that is called at the very beginning of the app lifecycle in the `main.ts`.
   * It ensures that authentication state is up-to-date before Vue app is rendered.
   *
   * @param {() => void} mountAppCb - Callback function to mount the app after authentication is initialized.
   * @returns {Promise<void>} Resolves when the authentication provider is initialized and the app is mounted.
   */
  async initializeAuthProvider(mountAppCb: () => void): Promise<void> {
    this.initAxiosInterceptor();

    // NOTE: Getting initial Okta authentication state on the app startup.
    // The method name is 'updateAuthState', but in fact it actualizes auth state for current user
    // loading state from Okta server and matching it with state in the local storage.
    const authState = await this.client.authStateManager.updateAuthState();
    this.setAccessToken(authState.accessToken?.accessToken);

    mountAppCb();

    await this.setUserInfo();
    this.setIsAuthReady(true);

    if (this.user !== null) {
      await this.notifyUserChange(
        OnUserChangeCallbackEventType.SIGNED_IN,
        this.user
      );
    }

    this.registerOnAuthStateChange();
  }

  /**
   * Waits for the authentication provider to be ready.
   *
   * It is used in a few places of the Vue app where it is important to wait for
   * authentication flow to complete and update internal status of the auth client
   * like access token and current user.
   * For instance, the `getUser` method uses this method to ensure user is loaded.
   *
   * The `setIsAuthReady` method is called when the auth flow is ready and it resolved
   * all Promises waiting for the valid authentication state.
   *
   * @returns {Promise<void>} Resolves when the authentication provider is ready.
   * @see {setIsAuthReady}
   */
  async waitForAuthIsReady(): Promise<void> {
    if (this.isAuthReady) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      this.waitForAuthIsReadyCallbacks.push(resolve);
    });
  }

  // NOTE: Okta auth state is handled by the authStateManager and I haven't found any case
  // when we need to wait for a specific event to occur.
  async waitForAuthStateChange(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Updates Axios clients by applying Okta auth interceptors to look for 401 response errors
   * and attempt to refresh access token for the failed request.
   *
   * @private
   */
  private initAxiosInterceptor() {
    const axiosInterceptor = new OktaAxiosInterceptor(this.client);
    axiosInterceptor.init(noRetryClient);
    axiosInterceptor.init(retryClient);
  }

  /**
   * Creates and registers a listener for Okta authentication state changes.
   *
   * It receives an updated `AuthState` from Okta and decides if this is a new signed in user,
   * or a state with no user meaning that a user has signed out.
   * As a result, it builds a message to Studio listeners about user state update
   * and notifies them using the `notifyUserChange` method.
   *
   * @private
   */
  private registerOnAuthStateChange(): void {
    const isSameUser = (
      previousUser: UserInfo | null,
      currentUser: UserInfo | null
    ) => {
      if (previousUser === null || currentUser === null) {
        return false;
      }

      if (
        previousUser.id === currentUser.id &&
        currentUser.email === previousUser.email &&
        currentUser.name === currentUser.name &&
        previousUser.accountId === currentUser.accountId
      ) {
        return true;
      }
      return false;
    };

    const handler = async (authState: AuthState | null) => {
      this.setAccessToken(
        authState?.accessToken ? authState.accessToken.accessToken : null
      );
      const previousUser = this.user;
      await this.setUserInfo();

      if (!isSameUser(previousUser, this.user)) {
        let event: OnUserChangeCallbackEventType =
          OnUserChangeCallbackEventType.USER_UPDATED;

        if (previousUser === null && this.user !== null) {
          event = OnUserChangeCallbackEventType.SIGNED_IN;
        } else if (previousUser !== null && this.user === null) {
          event = OnUserChangeCallbackEventType.SIGNED_OUT;
        }
        await this.notifyUserChange(event, this.user);
      }
    };

    // NOTE: Subscribe to Okta auth state change events.
    // It is important to start OktaAuth service with `this.client.start` to receive
    // the events while application is running.
    // Note that the events are not coming instantly. For instance, if a user is signed out
    // in the Okta admin console this event is not received right away and the user can continue
    // using the Studio for some time.
    this.authStateChangeCallback = handler.bind(this);
    this.client.authStateManager.subscribe(this.authStateChangeCallback);

    void this.client.start();
  }

  /**
   * Method to notify listeners about user state updates like signed in or signed out.
   *
   * This method notifies all listeners added using the `onUserChange` method.
   * In Studio there is only one listener (`authStore.handleUserChange`) configured in the App.vue.
   *
   * @private
   * @param {...Parameters<OnUserChangeCallback>} callbackInputs - Arguments to be passed to the `onUserChange` callback.
   * @returns {Promise<void>} Resolves when the notification process is complete.
   * @see {onUserChange}
   */
  private async notifyUserChange(
    ...callbackInputs: Parameters<OnUserChangeCallback>
  ): Promise<void> {
    await Promise.all(
      this.onUserChangeCallbacks.map((cb) => cb(...callbackInputs))
    );
  }

  private setIsAuthReady(isAuthReady: boolean): void {
    this.isAuthReady = isAuthReady;
    if (isAuthReady) {
      while (this.waitForAuthIsReadyCallbacks.length > 0) {
        const resolve = this.waitForAuthIsReadyCallbacks.shift();
        if (resolve) {
          resolve();
        }
      }
    }
  }

  private setAccessToken(token?: string | null): void {
    this.accessToken = token ?? null;
  }

  private async setUserInfo(): Promise<void> {
    try {
      if (this.client.getAccessToken()) {
        this.user = await this.getUserInfo();
      } else {
        this.user = null;
      }
    } catch (err) {
      if (err instanceof AuthNetworkError) {
        await this.closeSession();
        await this.notifyUserChange(
          OnUserChangeCallbackEventType.NETWORK_ERROR,
          null
        );
      } else if (err instanceof AuthTokenExpired) {
        await this.closeSession();
        await this.notifyUserChange(
          OnUserChangeCallbackEventType.SESSION_EXPIRED,
          null
        );
      } else {
        await this.closeSession();
        await this.notifyUserChange(
          OnUserChangeCallbackEventType.UNEXPECTED_ERROR,
          null
        );
      }
    }
  }

  private async getUserInfo(): Promise<UserInfo> {
    try {
      const { data: output } = await apiClient.get<UserInfo>({
        url: '/users/me',
        retryOnFail: true,
      });

      if (output === null || output === undefined) {
        throw new UnexpectedError();
      }

      return output;
    } catch (err) {
      if (isAxiosError(err) && err.response?.status === 401) {
        throw new AuthTokenExpired();
      } else if (
        isAxiosError(err) &&
        (err.response?.status === 0 || !err.response)
      ) {
        throw new AuthNetworkError();
      } else {
        logger.error(new Error(`Call to '/users/me' has failed`), {
          user: this.user,
          error: err,
        });
        throw new UnexpectedError();
      }
    }
  }

  /**
   * Closes the current session.
   *
   * This method clears internal client state, revokes Okta access token, clears Okta tokens,
   * removes internal auth state listener and stops Okta auth state manager as well as Okta session.
   *
   * This method does not cause any external redirects by Okta auth and can be used along with router.
   *
   * @private
   * @async
   * @returns {Promise<void>} Resolves when the session is closed.
   */
  private async closeSession(): Promise<void> {
    this.setAccessToken(null);
    this.user = null;
    await this.client.stop();
    if (this.authStateChangeCallback !== undefined) {
      this.client.authStateManager.unsubscribe(this.authStateChangeCallback);
      this.authStateChangeCallback = undefined;
    }
    await this.client.revokeAccessToken();
    this.client.tokenManager.clear();
    await this.client.session.close();
  }
}
