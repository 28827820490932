import { formatNumber } from './number-utils';

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} string - The string to capitalize.
 * @param {boolean} [makeOtherLettersLowerCase=true] - Whether to make other letters lowercase.
 * @returns {string} - The formatted string.
 */
export const capitalizeFirstLetter = (
  string: string,
  makeOtherLettersLowerCase = true
): string => {
  const lowerCaseStr = makeOtherLettersLowerCase
    ? string.toLowerCase()
    : string;
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1) || '';
};

/**
 * Formats a string from camelCase to regular sentence.
 *
 * @param {string} text - The camelCase string.
 * @returns {string} - The formatted string.
 */
export const formatCamelCase = (text: string): string =>
  capitalizeFirstLetter(
    text
      .replace(/(?:^|\.?)([A-Z])/g, (_x: string, y: string) => {
        return ' ' + y.toLowerCase();
      })
      .replace(/^_/, '')
  );

/**
 * Truncates a string to a specified length and appends a clamp if necessary.
 *
 * @param {string} text - The string to truncate.
 * @param {number} length - The length to truncate to.
 * @param {string} [clamp='...'] - The string to append if truncated.
 * @returns {string} - The truncated string.
 */
export const truncate = (
  text: string,
  length: number,
  clamp = '...'
): string => {
  return text.length > length ? text.slice(0, length) + clamp : text;
};

/**
 * Gets the first word in a string.
 *
 * @param {string} string - The string to process.
 * @returns {string} - The first word.
 */
export const getFirstWord = (string: string): string => {
  if (string) {
    const firstWord = string.split(' ', 1).shift();
    return firstWord ?? '';
  }
  return '';
};

/**
 * Converts camelCase string to snake_case.
 *
 * @param {string} str - The camelCase string.
 * @returns {string} - The snake_case string.
 */
export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Returns a dash if the input string is empty, otherwise returns the string.
 *
 * @param {string} string - The input string.
 * @returns {string} The original string or a dash if empty.
 */
export const dashOnEmpty = (string: string): string => {
  if (string === '0') {
    return '-';
  }
  return string || '-';
};

/**
 * Checks if the search query is included in the item's content.
 *
 * This function normalizes both the `itemContent` and `searchQuery` by converting
 * them to lowercase and removing all spaces. It then checks if the prepared
 * `searchQuery` is a substring of the prepared `itemContent`.
 *
 * @param {string} itemContent - The content of the item to search within.
 * @param {string} searchQuery - The search string to check for.
 * @returns {boolean} - Returns `true` if the normalized `searchQuery` is found
 *                      within the normalized `itemContent`; otherwise, `false`.
 */
export const doesSearchMatch = (
  itemContent: string,
  searchQuery: string
): boolean => {
  const prepareStringForSearch = (str: string) =>
    str.toLowerCase().replaceAll(' ', '');

  return prepareStringForSearch(itemContent).includes(
    prepareStringForSearch(searchQuery)
  );
};

/**
 * Formats a currency object into a readable string.
 *
 * @param {object} currencyObject - The currency object containing the currency and value.
 * @param {string} currencyObject.currency - The currency type (e.g., 'USD').
 * @param {number} currencyObject.value - The numerical value.
 * @returns {string} The formatted currency string.
 */
export const getCurrency = (currencyObject: {
  currency: string;
  value: number;
}): string => {
  if (!currencyObject.value) {
    return `${currencyObject.currency} -`;
  }
  return `${currencyObject.currency}${formatNumber(currencyObject.value)}`;
};

/**
 * Formats a number as a currency string.
 *
 * @param {number} amount - The amount to format.
 * @param {number} [maximumFractionDigits=0] - Maximum number of fraction digits.
 * @returns {string} The formatted currency string.
 */
export const formatCurrency = (
  amount: number,
  maximumFractionDigits = 0
): string => {
  const formattedAmount = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
  });
  return formattedAmount.format(amount).replace('US', '');
};
