import * as Sentry from '@sentry/vue';
import { isProd } from '@/runtime-env';

const makeLogger =
  (severity: 'log' | 'info' | 'warn') =>
  (message?: string, ...extra: unknown[]) => {
    console[severity](message, ...extra);
    if (severity === 'warn') {
      if (extra.length) {
        Sentry.setExtra('extra', extra);
      }
      Sentry.captureMessage(
        typeof message === 'string' ? message : JSON.stringify(message),
        'warning' as Sentry.SeverityLevel
      );
    }
  };

export const logger = {
  log: makeLogger('log'),
  info: makeLogger('info'),
  warn: makeLogger('warn'),
  /**
   * Log exception to the console and send it to Sentry's error tracking service.
   * @param error Error object; When in prod, this is passed to Sentry so it can produce stack traces for debugging.
   * @param extra Additional data to log to the console and send to Sentry.
   */
  error: (error: unknown, ...extra: unknown[]): void => {
    if (!isProd) console.error('Custom logger', error, ...extra);
    if (extra) {
      Sentry.setExtra('extra', extra);
    }
    Sentry.captureException(
      error instanceof Error ? error : new Error(String(error))
    );
  },
};
