import { AccountsErrorCodes } from '@/store/errors/accounts/errors';
import { BackendError, isBackendError } from '@/store/errors';

export const isEmailAlreadyExistsError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.EMAIL_ALREADY_EXISTS
  );
};

export const isEmailValidationFailedError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.EMAIL_VALIDATION_FAILED
  );
};

export const isPasswordValidationFailedError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.PASSWORD_VALIDATION_FAILED
  );
};

export const isUserCreationError = (error: unknown): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.USER_CREATION_FAILED
  );
};

export const isFailedToCreateBuyerPlatformSeatError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.FAILED_CREATE_BUYER_PLATFORM_SEAT
  );
};

export const isFailedToPersistBuyerPlatformSeatError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.FAILED_PERSIST_BUYER_PLATFORM_SEAT
  );
};

export const isFailedToUpdateDealsForTaxonomyAudiences = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.FAILED_UPDATED_DEALS_TAXONOMY
  );
};

export const isFailedToCreateSSPSeatError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.FAILED_CREATE_SSP_SEAT
  );
};

export const isFailedToPersistSSPSeatError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.FAILED_PERSIST_SSP_SEAT
  );
};

export const isMemberInviteNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.MEMBER_INVITE_NOT_FOUND
  );
};

export const isMemberInviteNotCreatedError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.MEMBER_INVITE_NOT_CREATED
  );
};

export const isMemberInviteAccountMismatchError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.MEMBER_INVITE_ACCOUNT_MISMATCH
  );
};

export const isUserNotFoundError = (error: unknown): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.USER_NOT_FOUND
  );
};

export const isAccountNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.NO_ACCOUNT_FOUND
  );
};

export const isMemberHasAcceptedInviteError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.MEMBER_HAS_ACCEPTED_INVITE
  );
};

export const isUserNotAdminError = (error: unknown): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.USER_NOT_ADMIN
  );
};

export const isAccountHasAudiencesError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.ACCOUNT_HAS_ACTIVE_AUDIENCE
  );
};

export const isDeleteNotAllowedError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.DELETE_NOT_ALLOWED
  );
};

export const isBuyerPlatformSeatHasDealsError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.BUYER_PLATFORM_SEAT_HAS_DEALS
  );
};

export const isDSPSeatNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.BUYER_PLATFORM_SEAT_NOT_FOUND
  );
};

export const isBuyerPlatformSeatAccountMismatchError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.BUYER_PLATFORM_SEAT_ACCOUNT_MISMATCH
  );
};

export const isSSPSeatNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.SSP_SEAT_NOT_FOUND
  );
};

export const isSSPSeatAccountMismatchError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.SSP_SEAT_ACCOUNT_MISMATCH
  );
};

export const isUserAlreadyHasProfileError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AccountsErrorCodes.USER_ALREADY_HAS_PROFILE
  );
};

export const isBuyerPlatformSeatAlreadyExistsError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.BUYER_PLATFORM_SEAT_ALREADY_EXIST_ERROR
  );
};

export const isTaxonomyDealsCreationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AccountsErrorCodes.TAXONOMY_DEALS_CREATION_ERROR
  );
};

export const accountsErrorsGuard = Object.freeze({
  isFailedToCreateBuyerPlatformSeatError,
  isFailedToPersistBuyerPlatformSeatError,
  isFailedToUpdateDealsForTaxonomyAudiences,
  isFailedToCreateSSPSeatError,
  isFailedToPersistSSPSeatError,
  isMemberInviteNotFoundError,
  isMemberInviteNotCreatedError,
  isMemberInviteAccountMismatchError,
  isUserNotFoundError,
  isAccountNotFoundError,
  isMemberHasAcceptedInviteError,
  isUserNotAdminError,
  isAccountHasAudiencesError,
  isDeleteNotAllowedError,
  isBuyerPlatformSeatHasDealsError,
  isDSPSeatNotFoundError,
  isBuyerPlatformSeatAccountMismatchError,
  isSSPSeatNotFoundError,
  isSSPSeatAccountMismatchError,
  isEmailAlreadyExistsError,
  isEmailValidationFailedError,
  isPasswordValidationFailedError,
  isUserCreationError,
  isUserAlreadyHasProfileError,
  isBuyerPlatformSeatAlreadyExistsError,
  isTaxonomyDealsCreationError,
});
