import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';
import {
  onlyAccountMembers,
  onlyInstantlyAuthorized,
  onlyProgrammaticallyAllowed,
  onlyUnauthenticated,
} from './guards';
import { tracking } from '@/utils/tracking';

export enum RouteType {
  AUTH = 'auth',
  DASHBOARD = 'dashboard',
  PUBLIC = 'public',
}
export const routerHistory = createWebHistory();
export const router = createRouter({
  history: routerHistory,
  strict: true,
  routes: [
    {
      path: '/',
      component: () => import('@/views/Dashboard.vue'),
      meta: { layout: 'MainLayout', auth: true },
      beforeEnter: [onlyInstantlyAuthorized, onlyAccountMembers],
      redirect: routes.overview,
      children: [
        {
          path: '/',
          component: () => import('@/views/Overview.vue'),
          children: [
            {
              path: routes.overview.path,
              name: routes.overview.name,
              component: () => import('@/views/Overview.vue'),
              meta: {
                routeType: RouteType.DASHBOARD,
              },
            },
          ],
        },
        {
          path: routes.createAudience.path,
          name: routes.createAudience.name,
          component: () => import('@/views/CreateAudience.vue'),
          meta: {
            isAudienceRelated: true,
            routeType: RouteType.DASHBOARD,
          },
        },
        {
          path: routes.audiences.table.path,
          name: routes.audiences.table.name,
          component: () => import('@/views/AudienceTable.vue'),
          meta: {
            isAudienceRelated: true,
            routeType: RouteType.DASHBOARD,
          },
        },
        {
          path: routes.measure.measure.path,
          name: routes.measure.measure.name,
          meta: {
            routeType: RouteType.DASHBOARD,
            isMeasureRelatedPage: true,
          },
          component: () => import('@/views/Measure.vue'),
          redirect: (to) => {
            // NOTE: Access the parameters from the current route (the route being redirected from)
            const params = to.params;

            return {
              name: routes.measure.attributionPixelGroups.list.name,
              params: params,
            };
          },
          children: [
            {
              path: routes.measure.attributionPixelGroups.list.path,
              name: routes.measure.attributionPixelGroups.list.name,
              component: () => import('@/components/Measure/Table/Table.vue'),
            },
            {
              path: routes.measure.attributionPixelGroups.create.path,
              name: routes.measure.attributionPixelGroups.create.name,

              component: () => import('@/components/Measure/Create/Create.vue'),
            },
            {
              path: routes.measure.attributionPixelGroups.details.path,
              meta: {
                routeType: RouteType.DASHBOARD,
                isMeasureRelatedPage: true,
              },
              component: () =>
                import(
                  '@/components/Measure/AttributionPixelGroup/AttributionPixelGroup.vue'
                ),
              children: [
                {
                  name: routes.measure.attributionPixelGroups.pixelGroup.name,
                  path: routes.measure.attributionPixelGroups.pixelGroup.path,
                  redirect: (to) => {
                    // NOTE: Access the parameters from the current route (the route being redirected from)
                    const params = to.params;

                    return {
                      name: routes.measure.attributionPixelGroups.details.name,
                      params: params,
                    };
                  },
                },
                {
                  path: routes.measure.attributionPixelGroups.details.path,
                  name: routes.measure.attributionPixelGroups.details.name,
                  component: () =>
                    import(
                      '@/components/Measure/AttributionPixelGroup/Details/DetailsWrapper.vue'
                    ),
                },
                {
                  path: routes.measure.attributionPixelGroups.analytics.path,
                  name: routes.measure.attributionPixelGroups.analytics.name,
                  component: () =>
                    import(
                      '@/components/Measure/AttributionPixelGroup/Insights/Insights.vue'
                    ),
                },
                {
                  path: routes.measure.attributionPixelGroups.reports.path,
                  name: routes.measure.attributionPixelGroups.reports.name,
                  component: () =>
                    import(
                      '@/components/Measure/AttributionPixelGroup/AggregateReports/AggregateReports.vue'
                    ),
                },
                {
                  path: routes.measure.attributionPixelGroups.debug.path,
                  name: routes.measure.attributionPixelGroups.debug.name,
                  component: () =>
                    import(
                      '@/components/Measure/AttributionPixelGroup/Debug/Debug.vue'
                    ),
                },
              ],
            },
          ],
        },
        {
          path: routes.labs.labs.path,
          name: routes.labs.labs.name,
          meta: {
            isLabsRelatedPage: true,
            routeType: RouteType.DASHBOARD,
          },
          component: () => import('@/views/Labs.vue'),
          children: [
            {
              path: routes.labs.labsList.path,
              name: routes.labs.labsList.name,
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
              component: () => import('@/components/Labs/LabsList.vue'),
            },
            // Released Labs START
            // These routes are kept to redirect users to the new routes
            {
              path: routes.labs.personas.path,
              name: routes.labs.personas.name,
              redirect: routes.createAudience,
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              path: routes.labs.attributionPixelGroupList.path,
              name: routes.labs.attributionPixelGroupList.name,
              redirect: routes.measure.attributionPixelGroups.list,
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              name: routes.labs.attributionPixelGroup.name,
              path: routes.labs.attributionPixelGroup.path,
              redirect: (to) => {
                // NOTE: Access the parameters from the current route (the route being redirected from)
                const params = to.params;
                return {
                  name: routes.measure.attributionPixelGroups.details.name,
                  params: params,
                };
              },
              // Released Labs END
            },
            {
              path: routes.labs.brandPersonas.path,
              name: routes.labs.brandPersonas.name,
              component: () =>
                import('@/components/Labs/BrandPersonas/BrandPersonas.vue'),
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              path: routes.labs.syntheticPanel.path,
              name: routes.labs.syntheticPanel.name,
              component: () =>
                import('@/components/Labs/SyntheticPanel/SyntheticPanel.vue'),
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              path: routes.labs.assistant.path,
              name: routes.labs.assistant.name,
              component: () =>
                import('@/components/Labs/Assistant/Assistant.vue'),
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              path: routes.labs.dataExplorer.path,
              name: routes.labs.dataExplorer.name,
              component: () =>
                import('@/components/Labs/DataExplorer/DataExplorer.vue'),
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              path: routes.labs.infiniteCanvas.path,
              name: routes.labs.infiniteCanvas.name,
              component: () =>
                import('@/components/Labs/InfiniteCanvas/InfiniteCanvas.vue'),
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              path: routes.labs.nodeBuilder.path,
              name: routes.labs.nodeBuilder.name,
              component: () =>
                import('@/components/Labs/NodeBuilder/NodeBuilder.vue'),
              meta: {
                isLabsRelatedPage: true,
                routeType: RouteType.DASHBOARD,
              },
            },
          ],
        },
        {
          path: routes.audiences.details.path,
          component: () => import('@/views/AudienceDetailsDashboard.vue'),
          meta: {
            isAudienceRelated: true,
            routeType: RouteType.DASHBOARD,
          },
          children: [
            {
              name: routes.audiences.audience.name,
              path: routes.audiences.audience.path,
              redirect: (to) => {
                // NOTE: Access the parameters from the current route (the route being redirected from)
                const params = to.params;

                return {
                  name: routes.audiences.details.name,
                  params: params,
                };
              },
            },
            {
              path: routes.audiences.details.path,
              name: routes.audiences.details.name,
              component: () => import('@/views/AudienceDetail.vue'),
            },
            {
              path: routes.audiences.trends.path,
              name: routes.audiences.trends.name,
              component: () => import('@/views/AudienceInsights.vue'),
            },
            {
              path: routes.audiences.health.path,
              name: routes.audiences.health.name,
              component: () => import('@/views/Health.vue'),
            },
            {
              path: routes.audiences.performance.path,
              name: routes.audiences.performance.name,
              component: () => import('@/views/Performance.vue'),
            },
          ],
        },
        {
          path: routes.settings.path,
          name: routes.settings.name,
          component: () => import('@/views/Settings.vue'),
          meta: { layout: 'MainLayout', auth: true },
          redirect: routes.account.info,
          children: [
            {
              path: routes.account.info.path,
              name: routes.account.info.name,
              component: () =>
                import('@/components/Settings/Account/Account.vue'),
              meta: {
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              path: routes.account.auth.path,
              name: routes.account.auth.name,
              component: () => import('@/components/Settings/Auth/Auth.vue'),
              meta: {
                routeType: RouteType.DASHBOARD,
              },
            },
            {
              path: routes.account.team.path,
              name: routes.account.team.name,
              component: () => import('@/components/Settings/Team/Team.vue'),
              meta: {
                routeType: RouteType.DASHBOARD,
              },
            },
          ],
        },
        {
          path: routes.publishers.content.path,
          name: routes.publishers.content.name,
          component: () => import('@/views/Publishers.vue'),
          meta: {
            routeType: RouteType.DASHBOARD,
            isPublishersRelatedPage: true,
          },
          children: [
            {
              path: routes.publishers.details.path,
              name: routes.publishers.details.name,
              component: () => import('@/views/PublisherDetails.vue'),
            },
          ],
        },
        {
          path: routes.publishers.create.path,
          name: routes.publishers.create.name,
          component: () => import('@/views/CreatePublisher.vue'),
          meta: {
            routeType: RouteType.DASHBOARD,
            isPublishersRelatedPage: true,
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/Auth.vue'),
      meta: { layout: 'AuthLayout' },
      redirect: routes.login,
      beforeEnter: [onlyUnauthenticated],
      children: [
        {
          path: routes.login.path,
          name: routes.login.name,
          component: () => import('@/components/Auth/Signin.vue'),
          meta: { initialAuth: true },
        },
      ],
    },
    {
      path: routes.authRedirect.path,
      name: routes.authRedirect.name,
      component: () => import('@/views/OktaAuthRedirect.vue'),
      meta: { layout: 'AuthLayout' },
    },
    {
      path: '/',
      component: () => import('@/views/Admin.vue'),
      meta: { layout: 'MainLayout', auth: true },
      children: [
        {
          path: routes.selectAccount.path,
          name: routes.selectAccount.name,
          component: () => import('@/components/Auth/SelectAccount.vue'),
          meta: { hideApplicationVersionsMismatchBanners: true },
        },
        {
          path: routes.switchAccount.path,
          name: routes.switchAccount.name,
          component: () => import('@/components/Auth/SwitchAccount.vue'),
          beforeEnter: [onlyProgrammaticallyAllowed],
        },
        {
          path: routes.admin.settings.path,
          name: routes.admin.settings.name,
          component: () => import('@/views/AdminSettings.vue'),
          meta: {
            layout: 'MainLayout',
            auth: true,
            isAdminRelatedPage: true,
            routeType: RouteType.DASHBOARD,
          },
          redirect: routes.admin.accounts,
          children: [
            {
              path: routes.admin.accounts.path,
              name: routes.admin.accounts.name,
              component: () =>
                import('@/components/Admin/Accounts/AdminAccounts.vue'),
            },
            {
              path: routes.admin.audiences.path,
              name: routes.admin.audiences.name,
              component: () =>
                import('@/components/Admin/Audiences/AdminAudiences.vue'),
            },
            {
              path: routes.admin.templates.path,
              name: routes.admin.templates.name,
              component: () =>
                import('@/components/Admin/Templates/AdminTemplates.vue'),
            },
            {
              path: routes.admin.create.path,
              name: routes.admin.create.name,
              component: () =>
                import('@/components/Admin/Create/AdminCreationPage.vue'),
            },
            {
              path: routes.admin.accountCreation.path,
              name: routes.admin.accountCreation.name,
              component: () =>
                import(
                  '@/components/Admin/Create/AdminAccountCreationForm.vue'
                ),
            },
            {
              path: routes.admin.userCreation.path,
              name: routes.admin.userCreation.name,
              component: () =>
                import('@/components/Admin/Create/AdminUserCreationForm.vue'),
            },
            {
              path: routes.admin.feedAudienceCreation.path,
              name: routes.admin.feedAudienceCreation.name,
              component: () =>
                import(
                  '@/components/Admin/Create/FeedAudienceCreationForm.vue'
                ),
            },
            {
              path: routes.admin.platformSeats.path,
              name: routes.admin.platformSeats.name,
              component: () =>
                import('@/components/Admin/Accounts/AdminPlatformSeats.vue'),
            },
            {
              path: routes.admin.deals.path,
              name: routes.admin.deals.name,
              component: () =>
                import('@/components/Admin/Deals/AdminDeals.vue'),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: routes.pageNotFound.path,
      name: routes.pageNotFound.name,
      meta: {
        layout: 'MainLayout',
        auth: true,
        routeType: RouteType.DASHBOARD,
      },
      beforeEnter: [onlyAccountMembers],
      component: () => import('@/views/PageNotFound.vue'),
    },
    {
      path: routes.pageForbidden.path,
      name: routes.pageForbidden.name,
      meta: {
        layout: 'MainLayout',
        auth: true,
        routeType: RouteType.DASHBOARD,
      },
      beforeEnter: [onlyAccountMembers],
      component: () => import('@/views/PageForbidden.vue'),
    },
    {
      path: '/',
      component: () => import('@/views/AudiencePlan.vue'),
      meta: { layout: 'PublicLayout' },
      redirect: routes.audiencePlan,
      children: [
        {
          path: routes.audiencePlan.path,
          name: routes.audiencePlan.name,
          component: () => import('@/views/AudiencePlan.vue'),
          meta: {
            routeType: RouteType.PUBLIC,
          },
        },
      ],
    },
  ],
});

router.beforeEach((to, _, next) => {
  let isNextCalled = false;
  const nextIfNotCalled = (_to?: { name: string }) => {
    if (!isNextCalled) {
      isNextCalled = true;
      if (_to) {
        next(_to);
      } else {
        next();
      }
    }
  };

  nextIfNotCalled();
});

router.onError((err: Error) => {
  const errorMessages = [
    'Failed to fetch dynamically imported module',
    "Unexpected token '<'",
  ];
  const hasModuleLoadError = errorMessages.some((message: string) => {
    const index = err.message.indexOf(message);
    return index === 0;
  });
  if (hasModuleLoadError) {
    window.location.href = `${location.pathname}?no-cache=${Date.now()}`;
  }
});

router.afterEach(() => {
  tracking.registerPageLoad();
});
