import { nextTick } from 'vue';

/**
 * Converts an object of styles to a string of CSS key-value pairs.
 *
 * @param {Object} styles - An object representing CSS styles where keys are CSS properties and values are the corresponding CSS values.
 * @returns {string} - A string representing the CSS styles, formatted as key-value pairs separated by semicolons.
 *
 */
export const convertStylesObjToString = (styles: object) => {
  return Object.entries(styles)
    .map(([k, v]) => `${k}:${v}`)
    .join(';');
};

/**
 * Makes matching characters in a string bold by wrapping them in <strong> tags with optional inline styles.
 *
 * @param {string} matchValue - The substring to match and apply bold styling to.
 * @param {string} item - The string where the match will be searched for and styled.
 * @param {Object} [styles={color: 'var(--color-black)', 'font-weight': 600}] - Optional styles to apply to the <strong> tags.
 * @returns {string} - The updated string with matching characters wrapped in <strong> tags with the applied styles.
 */
export const makeMatchingCharactersBolder = (
  matchValue: string,
  item: string,
  styles = { color: 'var(--color-black)', 'font-weight': 600 }
): string => {
  if (matchValue) {
    const re = new RegExp(matchValue, 'gi');
    return item.replace(
      re,
      (value) =>
        `<strong style="${convertStylesObjToString(styles)}">${value}</strong>`
    );
  } else {
    return item;
  }
};

/**
 * Smoothly scrolls to the bottom of a given HTML element.
 *
 * @param {HTMLElement | undefined} [element] - The HTML element to scroll.
 * @returns {Promise<void>} - A promise that resolves once the next tick has completed and the scroll is triggered.
 */
export const smoothScrollToBottom = async (
  element: HTMLElement | undefined
) => {
  await nextTick();
  if (element) {
    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth',
    });
  }
};
